import * as React from 'react'

import { Description, HistoryEdu } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'

import { AppContainer } from '../components'
import { marketAssetInformations } from '../data'

import type { MarketAsset } from '../queries'

type MarketAssetInformationsProps = {
  marketAsset?: MarketAsset
}

export const MarketAssetInformations = ({
  marketAsset,
}: MarketAssetInformationsProps) => {
  const symbol = marketAsset?.symbol || ''
  const assetInformation = marketAssetInformations[symbol]

  return assetInformation && (
    <AppContainer sx={{ p: 3, height: '100%' }}>
      <Typography
        variant='h5'
        textAlign='center'
        paddingBottom={2}
      >
        ¿Qué es {marketAsset?.name}?
      </Typography>
      <Typography
        textAlign='justify'
        paddingBottom={3}
      >
        {assetInformation.description}
      </Typography>
      <Stack
        direction='row'
        justifyContent='space-evenly'
      >
        {assetInformation.pitchDeckUrl && (
          <Button
            variant='contained'
            color='primary'
            startIcon={<Description />}
            target='_blank'
            href={assetInformation.pitchDeckUrl}
            rel='noopener noreferrer'
          >
            Pitch Deck
          </Button>
        )}
        {assetInformation.whitepaperUrl && (
          <Button
            variant='contained'
            color='primary'
            startIcon={<HistoryEdu />}
            target='_blank'
            href={assetInformation.whitepaperUrl}
            rel='noopener noreferrer'
          >
            Whitepaper
          </Button>
        )}
      </Stack>
    </AppContainer>
  )
}
