import * as React from 'react'

import { Currency } from './currency'

import type { UserNotification } from '../queries'

type UserNotificationContentProps = {
  notification: UserNotification
}

export const UserNotificationContent = ({
  notification,
}: UserNotificationContentProps) => {
  const notificationType = notification.notificationType
  const parsedContent = JSON.parse(notification.content)

  if (notificationType === 'BULK_PURCHASE') {
    const [amount, purchasePrice] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency='CLP'
          value={amount}
        />
        <br />
        <Currency
          currency='CLP/USDT'
          value={purchasePrice}
        />
      </React.Fragment>
    )
  } else if (notificationType === 'DEPOSIT_ADDRESS') {
    const [blockchain, symbol] = parsedContent
    return (
      <React.Fragment>
        <strong><small>Blockchain</small></strong> {blockchain}
        <br />
        <strong><small>Activo</small></strong> {symbol}
      </React.Fragment>
    )
  } else if (notificationType === 'FUND_PURCHASE') {
    const [currency, amount, fundName] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency={currency}
          value={amount}
        />
        <br />
        {fundName}
      </React.Fragment>
    )
  } else if (notificationType === 'FUND_SALE') {
    const [currency, amount, fundName] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency={currency}
          value={amount}
        />
        <br />
        {fundName}
      </React.Fragment>
    )
  } else if (notificationType === 'PURCHASE') {
    const [fromSymbol, fromAmount, toSymbol] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency={fromSymbol}
          value={fromAmount}
        />
        {toSymbol && ` > ${toSymbol}`}
      </React.Fragment>
    )
  } else if (notificationType === 'SWAP') {
    const [fromSymbol, fromAmount, toSymbol] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency={fromSymbol}
          value={fromAmount}
        />
        {' > '}
        {toSymbol}
      </React.Fragment>
    )
  } else if (notificationType === 'WITHDRAW_BANK') {
    const [symbol, amount] = parsedContent
    return (
      <Currency
        currency={symbol}
        value={amount}
      />
    )
  } else if (notificationType === 'WITHDRAW_BLOCKCHAIN') {
    const [symbol, amount, blockchain, address] = parsedContent
    return (
      <React.Fragment>
        <Currency
          currency={symbol}
          value={amount}
        />
        <br />
        {`${address} (${blockchain})`}
      </React.Fragment>
    )
  }
}
