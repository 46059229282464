import * as React from 'react'

import { Box } from '@mui/material'

import { CompositionGraph, PricesGraph } from '../../components'

import type { GraphType } from '..'
import type { GraphPoint, PortfolioBalance, PortfolioGraphData } from '../../services'

type CompositionGraphWrapperProps = {
  portfolioBalances: PortfolioBalance[]
}

const CompositionGraphWrapper = ({
  portfolioBalances,
}: CompositionGraphWrapperProps) => {
  const dummyParent = {
    id: 'portfolio',
    parent: null,
    size: null,
  }
  const totalAmount =
    portfolioBalances.reduce((acc, pb) => acc += pb.balanceGraphData.lastValue || 0, 0)

  const data = portfolioBalances
    .filter((pb) => (pb.balanceGraphData.lastValue || 0) > 0)
    .map((pb) => ({
      id: pb.symbol,
      parent: 'portfolio',
      size: pb.balanceGraphData.lastValue || 0,
    }))

  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <CompositionGraph
        totalQuoteAmount={totalAmount}
        data={[dummyParent, ...data]}
      />
    </Box>
  )
}

type PricesGraphWrapperProps = {
  graphUnit: string
  points: GraphPoint[]
  ath: number
  atl: number
}

const PricesGraphWrapper = ({
  graphUnit,
  points,
  ath,
  atl,
}: PricesGraphWrapperProps) => (
  <Box sx={{ py: 1, height: '100%' }}>
    <PricesGraph
      currency={graphUnit}
      data={points}
      ath={ath}
      atl={atl}
    />
  </Box>
)

type GraphSectionProps = {
  graphType: GraphType
  graphUnit: string
  portfolioBalances: PortfolioBalance[]
  portfolioGraphData: PortfolioGraphData
}

export const GraphSection = ({
  graphType,
  graphUnit,
  portfolioBalances,
  portfolioGraphData,
}: GraphSectionProps) => {
  switch (graphType) {
  case 'COMPOSITION': return (
    <CompositionGraphWrapper
      portfolioBalances={portfolioBalances}
    />
  )
  case 'TOTAL_AMOUNT': return (
    <PricesGraphWrapper
      graphUnit={graphUnit}
      points={portfolioGraphData.points}
      ath={portfolioGraphData.ath}
      atl={portfolioGraphData.atl}
    />
  )
  default: return null
  }
}
