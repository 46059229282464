import {
  GraphData,
  getBalanceGraphData,
  getPriceGraphData,
  getTotalAmount,
  getUnspentEarnedAmount,
  withSymbol,
} from '../services'

import type { AssetPrice, MarketAsset, UserOperation } from '../queries'

export type PortfolioBalance = {
  symbol: string
  totalAmount: number
  earnedAmount: number
  balanceGraphData: GraphData
  priceGraphData: GraphData
  marketAssetId: string
  marketAssetIndex: number
}

export const computePortfolioBalances = (
  marketAssets: MarketAsset[],
  operations: UserOperation[],
  usdPrices: AssetPrice[],
): PortfolioBalance[] => (
  marketAssets.map((marketAsset, index) => {
    const symbol = marketAsset.symbol
    const assetOperations = withSymbol(operations, symbol)

    const priceGraphData = getPriceGraphData(usdPrices, symbol)
    const balanceGraphData = getBalanceGraphData(priceGraphData, assetOperations)

    const totalAmount = getTotalAmount(symbol, assetOperations)
    const earnedAmount = getUnspentEarnedAmount(symbol, assetOperations)

    return {
      symbol,
      totalAmount,
      earnedAmount,
      balanceGraphData,
      priceGraphData,
      marketAssetId: marketAsset.id,
      marketAssetIndex: index,
    }
  })
)
