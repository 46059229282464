import * as React from 'react'

import { Box, CircularProgress, useTheme } from '@mui/material'
import { curveLinear } from '@visx/curve'
import { LinearGradient } from '@visx/gradient'
import { scaleLinear, scaleTime } from '@visx/scale'
import { AreaClosed, LinePath } from '@visx/shape'

import type { GraphPoint } from '../services'

const getX = (p: GraphPoint) => p.timestamp
const getY = (p: GraphPoint) => p.value

type BaseGraphProps = {
  isStableCoin?: boolean
  data: GraphPoint[]
  width: number
  height: number
}

const BaseGraph = ({
  isStableCoin,
  data,
  width,
  height,
}: BaseGraphProps) => {
  const palette = useTheme().palette
  const accentColor = palette.mode === 'light' ? palette.primary.main : palette.primary.light
  const lightOpacity = palette.action.disabledOpacity

  if (data.length === 1) {
    data.push({
      value: data[0].value,
      timestamp: new Date().getTime(),
    })
  }

  const firstPoint = data[0]
  const lastPoint = data[data.length - 1]
  const minPrice = Math.min(...data.map(getY))
  const maxPrice = Math.max(...data.map(getY))

  const minGraphY = isStableCoin ? minPrice * 0.999 : minPrice
  const maxGraphY = isStableCoin ? maxPrice * 1.001 : maxPrice

  const xAxisScale = React.useMemo(
    () =>
      scaleTime({
        domain: [getX(firstPoint), getX(lastPoint)],
        range: [0, width],
      }),
    [width, firstPoint, lastPoint],
  )
  const yAxisScale = React.useMemo(
    () =>
      scaleLinear({
        domain: [minGraphY, maxGraphY],
        range: [height, 0],
      }),
    [height, minGraphY, maxGraphY],
  )

  if (width < 10) {
    return null
  }

  return (
    <div style={{ touchAction: 'pan-y', display: 'flex' }}>
      <svg
        width={width}
        height={height}
      >
        <LinearGradient
          id='area-gradient'
          from={palette.primary.main}
          to={palette.primary.main}
          fromOpacity={.6 * lightOpacity}
          toOpacity={.6 * lightOpacity}
        />
        <AreaClosed<GraphPoint>
          data={data}
          x={(d) => xAxisScale(getX(d)) ?? 0}
          y={(d) => yAxisScale(getY(d)) ?? 0}
          yScale={yAxisScale}
          strokeWidth={2}
          stroke='transparent'
          fill='url(#area-gradient)'
          curve={curveLinear}
        />
        <LinePath
          data={data}
          x={(d) => xAxisScale(getX(d)) ?? 0}
          y={(d) => yAxisScale(getY(d)) ?? 0}
          stroke={accentColor}
          strokeOpacity={0.8 * lightOpacity}
          strokeWidth={4}
          style={{
            mixBlendMode: palette.mode === 'light' ? 'darken' : 'color-dodge',
          }}
        />
      </svg>
    </div>
  )
}

export const PricesMiniGraph = ({
  isStableCoin,
  data,
  width,
  height,
}: BaseGraphProps) => (
  data.length > 0 ? (
    <BaseGraph
      isStableCoin={isStableCoin}
      data={data}
      width={width}
      height={height}
    />
  ) : (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      width={width}
      height={height}
    >
      <CircularProgress />
    </Box>
  )
)
