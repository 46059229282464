import * as React from 'react'

import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { navigate } from '@reach/router'
import { ParentSize } from '@visx/responsive'

import {
  AppContainer,
  AssetBadge,
  Currency,
  Loading,
  PercentVariation,
  PricesMiniGraph,
} from '../../components'
import { getPriceGraphData } from '../../services'

import type { AssetPrice, MarketAsset } from '../../queries'

export const LoadingAssetDisplay = () => (
  <AppContainer
    sx={{
      gap: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    }}
    lg={6}
    xl={4}
  >
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
      sx={{ px: 2, pt: 2 }}
    >
      <Skeleton
        variant='circular'
        width={48}
        height={48}
      />
      <Stack>
        <Typography
          variant='h5'
          component='span'
        >
          <Skeleton width={120} />
        </Typography>
        <Typography
          variant='body1'
          component='span'
          color='text.secondary'
        >
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Stack>
    <Loading height='100px' />
  </AppContainer>
)

type AssetDisplayProps = {
  marketAsset: MarketAsset
  assetPrices: AssetPrice[]
}

export const AssetDisplay = ({
  marketAsset,
  assetPrices,
}: AssetDisplayProps) => {
  const navigateFn = () => navigate(`market/${marketAsset.symbol}`)

  const graphData = getPriceGraphData(assetPrices || [], marketAsset.symbol)
  const hasNoData = graphData.points.length === 0

  return (
    <AppContainer
      onClick={navigateFn}
      sx={{
        cursor: 'pointer',
        gap: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      lg={6}
      xl={4}
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        sx={{ px: 2, pt: 2 }}
      >
        <AssetBadge
          symbol={marketAsset.symbol}
          height={48}
        />
        <Stack>
          <Typography
            variant='h5'
            component='span'
          >
            {marketAsset.name}
          </Typography>
          <Typography
            variant='body1'
            component='span'
            color='text.secondary'
          >
            {marketAsset.symbol}
          </Typography>
        </Stack>
        <Stack flexGrow={1}>
          <Typography
            variant='h5'
            component='span'
            textAlign='right'
          >
            {(hasNoData) ? (
              '--'
            ) : (
              <Currency
                currency='USD'
                value={graphData.lastValue}
              />
            )}
          </Typography>
          <Typography variant='body2'>
            {(hasNoData) ? (
              '--'
            ) : (
              <PercentVariation
                value={graphData.variation}
              />
            )}
          </Typography>
        </Stack>
      </Stack>
      {(hasNoData) ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          px={3}
          pb={3}
        >
          No hay información de precios para este periodo.
        </Box>
      ) : (
        <Box height={100}>
          <ParentSize>
            {({ width }) => (
              <PricesMiniGraph
                isStableCoin={['USDT', 'USDC'].includes(marketAsset.symbol)}
                data={graphData.points}
                width={width}
                height={100}
              />
            )}
          </ParentSize>
        </Box>
      )}
    </AppContainer>
  )
}
