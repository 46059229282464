import * as React from 'react'

import { Stack, Typography } from '@mui/material'
import { timeFormat } from 'd3-time-format'

import { AppContainer, Currency } from '../components'

import type { GraphData } from '../services'

export type MarketAssetPriceProps = {
  graphData: GraphData
  currency: string
}

export const MarketAssetPrice = ({
  graphData,
  currency,
}: MarketAssetPriceProps) => (
  <AppContainer
    md={6}
    sx={{ p: 3, height: '100%' }}
  >
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={1}
      sx={{ height: '100%' }}
    >
      <Typography
        variant='h5'
        component='p'
        fontWeight='medium'
        lineHeight={1}
      >
        Precio {graphData.symbol}
        <br />
        <Typography
          variant='caption'
          component='span'
          fontWeight='400'
          lineHeight={1}
        >
          {graphData.lastTimestamp ? (
            `al ${timeFormat('%Y-%m-%d %H:%M')(new Date(graphData.lastTimestamp))}`
          ) : (
            'No hay información de precios para este periodo.'
          )}
        </Typography>
      </Typography>
      {graphData.lastValue && (
        <Typography
          variant='h5'
          component='p'
          fontWeight='400'
          lineHeight={1}
          textAlign='right'
        >
          <Currency
            currency={currency}
            value={graphData.lastValue}
          />
        </Typography>
      )}
    </Stack>
  </AppContainer>
)
