export * from './apollo_client'
export * from './auth'
export * from './banks'
export * from './base_apys'
export * from './birthdate_validation'
export * from './countries'
export * from './download_blob'
export * from './error_messages'
export * from './face_detection'
export * from './format'
export * from './graph_data'
export * from './heic_to_jpeg'
export * from './identity_proofs'
export * from './market_assets'
export * from './operations_to_csv'
export * from './portfolios'
export * from './query_string'
export * from './render_rich_text'
export * from './rut_validation'
export * from './sii_activity_codes'
export * from './ultimate_beneficial_owners'
export * from './upload_to_storage'
export * from './user_agent'
export * from './user_operations'
export * from './validation'

export const onlyUnique = (array: string[]) =>
  array.filter((value, index, arr) => index === arr.indexOf(value))
