import * as React from 'react'

import { Skeleton, Typography } from '@mui/material'

import { Currency } from '../../components'

import type { MarketAsset } from '../../queries'
import type { PortfolioBalance } from '../../services'

type EarningsDisplayProps = {
  sadMode: boolean
  marketAsset: MarketAsset | null
  portfolioBalance: PortfolioBalance | null
}

export const EarningsDisplay = ({
  sadMode,
  marketAsset,
  portfolioBalance,
}: EarningsDisplayProps) => (
  <Typography
    variant='body1'
    sx={{
      px: 2,
      py: 1,
      border: 1,
      borderRadius: 2,
      borderColor: sadMode ? 'text.disabled' : 'primary.main',
      color: sadMode ? 'text.disabled' : 'text.primary',
      lineHeight: 1.05,
    }}
  >
    {(marketAsset !== null && portfolioBalance !== null) ? (
      <React.Fragment>
        <Currency
          currency={marketAsset.symbol}
          value={portfolioBalance.earnedAmount}
        />
        <br />
      </React.Fragment>
    ) : (
      <Skeleton />
    )}
    <span style={{ fontSize: '.75em', fontWeight: 400 }}>
      Recompensa acumulada
    </span>
  </Typography>
)
