import * as React from 'react'

import { Grid } from '@mui/material'

import { UserAssetDisplay } from './user_asset_display'
import { mapBaseApys } from '../../services'

import type { BaseApy, MarketAsset } from '../../queries'
import type { PortfolioBalance } from '../../services'

export type UserAssetsDisplayProps = {
  graphUnit: string
  marketAssets: MarketAsset[]
  portfolioBalances: PortfolioBalance[]
  baseApys: BaseApy[]
}

export const UserAssetsDisplay = ({
  graphUnit,
  marketAssets,
  portfolioBalances,
  baseApys,
}: UserAssetsDisplayProps) => {
  const baseApysMap = mapBaseApys(baseApys)

  const sortedAssets = portfolioBalances
    .sort((a, b) => (
      (b.balanceGraphData.lastValue || 0) - (a.balanceGraphData.lastValue || 0)
    ))

  return (
    <Grid
      item
      container
      rowSpacing={2}
      columnSpacing={3}
    >
      {(sortedAssets.length === 0) ? (
        Array(5).fill(null).map((_, index) => (
          <UserAssetDisplay
            key={index}
            graphUnit={graphUnit}
            marketAsset={null}
            portfolioBalance={null}
            baseApy={null}
          />
        ))
      ) : (
        sortedAssets.map((portfolioBalance, index) => (
          <UserAssetDisplay
            key={index}
            graphUnit={graphUnit}
            marketAsset={marketAssets[portfolioBalance.marketAssetIndex]}
            portfolioBalance={portfolioBalance}
            baseApy={baseApysMap[portfolioBalance.marketAssetId]}
          />
        ))
      )}
    </Grid>
  )
}
